
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission
} from "@/app/infrastructures/misc/Utils";
import {
  REFERRAL,
  SHIPMENT_STT_BOOKING
} from "@/app/infrastructures/misc/RolePermission";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { defineAsyncComponent } from "@vue/runtime-core";
import OcbcPopupModal from "@/app/ui/components/ocbc-popup-modal/index.vue";
import ReferralModal from "@/app/ui/views/dashboard/component/referral/referral-modal.vue";
import { ProgressiveCommissionConfigurationController } from "@/app/ui/controllers/ProgressiveCommissionConfigurationController";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import QRCode from "qrcode";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import { flags } from "@/feature-flags";

const ShortcutBooking = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/shortcut-booking/index.vue")
);
const BannerDashboard = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/pos/banner-dashboard.vue")
);
const ReferralBelanja = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/referral-belanja/index.vue")
);
const BNIModal = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/bni/index.vue")
);

@Options({
  components: {
    ShortcutBooking,
    BannerDashboard,
    OcbcPopupModal,
    ReferralModal,
    ReferralBelanja,
    BNIModal
  }
})
export default class MainDashboardV2 extends Vue {
  // account type
  get dataProfile() {
    return AccountController.accountData;
  }

  get accountType() {
    return this.dataProfile.account_type;
  }

  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  // referral
  get referralCode() {
    return this.dataProfile.account_type_detail.partnerReferralCode;
  }

  get isReferralCode() {
    return checkRolePermission(REFERRAL.ENABLE);
  }

  get isCreateManualAble() {
    return FlagsPermissionBooking.permission_booking_create_manual.isEnabled();
  }

  get isCreateForCorporateAble() {
    return FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled();
  }

  get isAbleToCreateShipmentId() {
    return FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled();
  }

  get isAbleToCreateInstantBooking() {
    return FlagsPermissionBooking.permission_instant_booking_create.isEnabled();
  }

  get isProgressiveCommissionAvailable() {
    return ProgressiveCommissionConfigurationController
      .progressiveCommissionList.data.length;
  }

  get isPPOBVisible() {
    return flags.feature_ppob_enabled.isEnabled();
  }

  get isShortcutEnable() {
    return (
      this.isCreateManualAble ||
      this.isCreateForCorporateAble ||
      this.isAbleToCreateShipmentId ||
      this.isAbleToCreateInstantBooking ||
      this.isPPOBVisible
    );
  }

  get bannerData() {
    return this.BANNER_DATA.filter(banner => banner.permission);
  }

  // ocbc
  get isOcbcVisible() {
    return (
      FlagsMedusa.config_loan_ocbc.isEnabled() &&
      this.accountTypeDetail === "pos"
    );
  }

  ocbcDialogModelValue = false;

  toggleOcbcDialog(bool: boolean) {
    this.ocbcDialogModelValue = bool;
  }

  referralDialogModelValue = false;

  toggleReferalDialog(bool: boolean) {
    this.referralDialogModelValue = bool;
  }

  goToDetailBonusKomisi() {
    this.$router.push("/bonus-komisi");
  }

  BNIDialog = false;
  toggleBNIDialog(bool: boolean) {
    this.BNIDialog = bool;
  }

  referralBelanja = false;
  affiliateLink = "";
  qrCode = "";
  expiredAt = "";

  async goToModalReferalBelanja() {
    MainAppController.closeMessageModal();
    MainAppController.showLoading();
    try {
      const data = await DashboardController.getReferralAffiliate();
      if (data) {
        this.referralBelanja = true;
        this.affiliateLink = data.affiliatelink;
        this.qrCode = await this.convertQr(data.affiliatelink);
        this.expiredAt = data.expiredAt;
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Memuat Data!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  convertQr(text: string) {
    return QRCode.toDataURL(text, {
      errorCorrectionLevel: "Q",
      margin: 0,
      color: {
        light: "#EBECF0"
      }
    }).then((url: string) => {
      return url;
    });
  }

  get permissionBNIBanner() {
    return FlagsMedusa.banner_bni_kur_enable.isEnabled();
  }

  get BANNER_DATA() {
    return [
      {
        image: "banner-bni.webp",
        permission: this.permissionBNIBanner,
        action: () => {
          this.toggleBNIDialog(true);
        }
      },
      {
        image: "banner-referral-belanja.webp",
        permission: FlagsMedusa.config_pos_affiliate_banner.isEnabled(),
        action: () => {
          this.goToModalReferalBelanja();
        }
      },
      {
        image: "OCBC-Banner.png",
        permission: this.dataProfile.isPosParentAccount && FlagsMedusa.config_loan_ocbc.isEnabled(),
        action: () => {
          this.toggleOcbcDialog(true);
        }
      },
      {
        image: "banner-referral.webp",
        permission: this.isReferralCode,
        action: () => {
          this.toggleReferalDialog(true);
        }
      }
    ];
  }

}
