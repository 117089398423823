const term = (info: any) => {
  return `<ul style="list-style-type:disc">
        <li>Program POS Referral ini berlaku di bulan <strong>${info.period}</strong>.</li>
        <li>Agen Mitra POS yang berhasil mengajak teman untuk bergabung dan melakukan Top Up senilai <strong>${info.topUp}</strong> maka akan mendapatkan insentif sebesar <strong>${info.incentive}</strong>.</li>
        <li>Syarat untuk mendapatkan insentif yaitu: </li>
            <ul style="list-style-type: '-'; padding-left: 10px;">
                <li style="padding-left: 8px;"> Calon Agen Mitra POS wajib memilih <strong>Rekomendasi dari Agen POS Lain</strong> pada pertanyaan <strong>Darimana Anda mendapatkan informasi tentang pendaftaran ini </strong>.</li>
                <li style="padding-left: 8px;"> Mengisi <strong>Kode Referral</strong>  “POS Code”, contoh: ${info.code} pada form <strong>perorangan / perusahaan</strong> di website Lion Parcel. </li>
                <li style="padding-left: 8px;"> Lokasi POS telah disetujui oleh tim Lion Parcel. </li>
            </ul>
        <li>Insentif akan dihitung setelah Agen Mitra POS yang diajak sudah disetujui oleh Tim Lion Parcel dan telah melakukan Top Up saldo awal sebesar <strong>${info.topUp}</strong> dalam tempo waktu yang telah ditentukan.</li>
        <li>Insentif akan dibayarkan dalam jangka waktu ${info.paidPeriod} dalam bentuk Saldo POS.</li>
        <li>Tim NPOS akan melaporkan transaksi-transaksi yang terjadi kepada Tim Finance sesuai dengan aturan yang berlaku.</li>
        <li>Informasi lebih lanjut tentang program ini. Anda bisa menghubungi di ${info.phone}.</li>
        <li>Bagikan Kode Referral Anda kepada Calon Agen Mitra POS dengan menyalin link <strong>perorangan</strong>: <a href="https://lionparcel.com/agen/perorangan-app?referral=${info.code}" target="_blank" style="color: red">https://lionparcel.com/agen/perorangan-app?referral=${info.code}</a> atau menyalin link <strong>perusahaan</strong>: <a href="https://lionparcel.com/agen/perusahaan-app?referral=${info.code}" target="_blank" style="color: red">https://lionparcel.com/agen/perusahaan-app?referral=${info.code}</a>. Silahkan daftarkan Calon Agen Mitra POS Anda dengan klik tombol dibawah ini:</li>
    </ul>`;
};

export default term;
